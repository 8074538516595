export const allRoutes = {
  seriesHome: (id) => `/${id}/`,
  seriesNews: (id) => `${id}news/`,
  seriesVideos: (id) => `${id}videos/`,
  seriesFantasyArticles: (id) => `${id}fantasy-articles/`,
  seriesFixtures: (id) => `${id}schedule/`,
  seriesStandings: (id) => `${id}points-table/`,
  seriesStats: (id) => `${id}stats/`,
  seriesTeams: (id) => `${id}teams/`,
  seriesSquads: (id) => `${id}squads/`,
  seriesArchives: (id) => `${id}archives/`,
  seriesHomeAsFixtures: (id) => `/${id}/`,
  seriesFantasyTips: (id) => `${id}fantasy-tips/`,
  fixtures: '/live-scores/',
  fixturesUpcoming: '/live-scores/upcoming/',
  fixturesRecent: '/live-scores/recent/',
  cricketSchedule: '/upcoming-cricket-schedule/',
  cricketSeries: '/cricket-series/',
  cricketSeriesArchive: '/cricket-series-archive',
  matchDetail: (slug) => `/${slug}/`,
  matchDetailScorecard: (slug) => `${slug}full-scorecard/`,
  matchDetailCommentary: (slug) => `/${slug}/`,
  matchDetailStandings: (slug) => `${slug}points-table/`,
  matchDetailNews: (slug) => `${slug}news/`,
  matchDetailUpcoming: (slug) => `${slug}fixtures-and-results/`,
  matchDetailResult: (slug) => `${slug}results/`,
  matchDetailLiveBlog: (slug) => `${slug}live-blog/`,
  matchDetailFantasyTips: (slug) => `${slug}fantasy-tips/`,
  matchDetailOvers: (slug) => `${slug}overs/`,
  signUp: '/sign-up/',
  signIn: '/sign-in/',
  profile: '/profile/',
  profileEdit: '/profile/edit/',
  saveForLater: '/profile/saved-for-later/',
  forgotPassword: '/forgot-password/',
  home: '/',
  verifyPhoneNumber: '/verify-phone-number/',
  search: '/search/',
  searchNews: '/search/news/',
  searchVideo: '/search/video/',
  searchSeries: '/search/series/',
  searchPlayer: '/search/players/',
  searchTeam: '/search/team/',
  fantasyCricketTips: '/fantasy-cricket-tips/',
  iccRankings: '/icc-rankings/',
  iccRankingsWomen: '/icc-rankings/women/',
  cricketVideos: 'cricket-videos/',
  playlist: 'playlist/',
  cricketVideo: 'videos/',
  cricketSeriesPages: 'cricket-series/',
  authors: '/authors/',
  authorDetail: (slug) => `/author/${slug}/`,
  termsAndConditions: '/terms-conditions/',
  copyrightsNotice: '/copyright-notice/',
  writeForUs: '/write-for-us/',
  privacyPolicy: '/privacy-policy/',
  disclaimer: '/disclaimer/',
  dmca: '/dmca/',
  changePassword: '/change-password/',
  advertiseWithUs: '/advertise-with-us/',
  careers: '/careers/',
  aboutUs: '/about-us/',
  contactUs: '/contact-us/',
  gdprCompilance: '/gdpr-compilance/',
  affiliate: '/affiliate/',
  feedback: '/feedback/',
  blog: '/blog/',
  iccRankingTest: (rankingType) => `${allRoutes.iccRankings}${rankingType}-test/`,
  iccRankingOdi: (rankingType) => `${allRoutes.iccRankings}${rankingType}-odi/`,
  iccRankingT20i: (rankingType) => `${allRoutes.iccRankings}${rankingType}-t20i/`,
  iccRankingWomanTest: (rankingType) => `${allRoutes.iccRankingsWomen}${rankingType}-test/`,
  iccRankingWomanOdi: (rankingType) => `${allRoutes.iccRankingsWomen}${rankingType}-odi/`,
  iccRankingWomanT20i: (rankingType) => `${allRoutes.iccRankingsWomen}${rankingType}-t20i/`,
  comparePlayer: (playerOneSlug, playerTwoSlug) => `/cricket-players/compare/${playerOneSlug}${playerTwoSlug ? '-vs-' + playerTwoSlug : ''}`,
  webStories: '/web-stories/'
}

export const matchDetailTabSlug = ['full-scorecard', 'news', 'fixtures-and-results', 'results', 'fantasy-tips', 'overs', 'standings', 'live-blog']
export const cricketSeriesSlug = ['standings', 'stats', 'teams', 'squads', 'fantasy-tips', 'fixtures']
